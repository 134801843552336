import { combineReducers } from "redux"
import sessionReducer from './sessionReducer'
import pasteboardReducer from './pasteboardReducer'

const rootReducer = combineReducers({
  session: sessionReducer,
  pasteboard: pasteboardReducer
});

export default rootReducer;
