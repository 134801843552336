import React, {useEffect, useState} from "react";
import MainLayout from "../layout/mainLayout";
import {
  Box,
  ListItem,
  ListItemText,
  TextField,
  Typography,
  List,
  IconButton, Modal, Paper
} from "@mui/material";
import {Edit, AttachFile, Delete, FileDownload, Visibility} from '@mui/icons-material';
import {styled} from "@mui/material/styles";
import {DropzoneArea} from 'material-ui-dropzone';
import {useDispatch, useSelector} from "react-redux";
import {updateText, updateFiles} from "../redux/actions/pasteboardActions";
import {firebaseConfig} from '../services/firesbase'
import {initializeApp} from "firebase/app";
import {getStorage, ref as storageRef, uploadBytes, getDownloadURL, deleteObject} from "firebase/storage"
import {getDatabase, ref, onValue, update, get, off} from "firebase/database"

const StyledHeading = styled(Typography)(({theme}) => ({
  paddingLeft: '15px',
  paddingTop: '5px',
  paddingBottom: '5px'
}));

const PreviewImage = styled('img')(({theme}) => ({
  maxWidth: '80%',
  maxHeight: '80vh',
  display: 'flex',
  justifySelf: 'center',
  alignSelf: 'center',
  flex: '1'
}));

const previewModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const firebase = initializeApp(firebaseConfig)
const storage = getStorage(firebase)
const database = getDatabase(firebase)

const imageExtensions = ['.jpg', '.jpeg', '.svg', '.png', '.gif', '.tif', '.pcx', '.bmp'];

const Session = () => {
  const dispatch = useDispatch()
  const sessionId = useSelector((state) => state.session.sessionId)
  const text = useSelector((state) => state.pasteboard.text)
  const files = useSelector((state) => state.pasteboard.files)
  const [previewModalOpen, setPreviewModalOpen] = useState(false)
  const [previewImageUrl, setPreviewImageUrl] = useState();

  const addFileToList = async (fileName) => {
    if (sessionId) {
      const currentValueRef = await ref(database, sessionId)
      const currentValue = (await get(currentValueRef)).val()

      const dataRef = ref(database);
      const updates = {}

      if (currentValue) {
        const files = currentValue.files ? currentValue.files : []
        updates[sessionId] = {
          ...currentValue,
          files: [...files, fileName]
        }
      } else {
        updates[sessionId] = {
          text: "",
          files: [fileName]
        }
      }
      await update(dataRef, updates);
    }
  }

  const deleteFileFromList = async (fileName) => {
    if (sessionId) {
      const currentValueRef = await ref(database, sessionId)
      const currentValue = (await get(currentValueRef)).val()

      const dataRef = ref(database);
      const updates = {}

      if (currentValue) {
        const files = [...currentValue.files ? currentValue.files : []]
        const index = files.indexOf(fileName)
        files.splice(index, 1)
        updates[sessionId] = {
          ...currentValue,
          files: files
        }
      } else {
        updates[sessionId] = {
          text: "",
          files: [fileName]
        }
      }
      await update(dataRef, updates);
    }
  }

  const isImage = (filename) => {
    return imageExtensions.some(extension => {
      return filename.indexOf(extension) !== -1
    })
  }

  const fetchLink = async (fileName) => {
    if (sessionId) {
      const ref = storageRef(storage, sessionId + '/' + fileName)
      getDownloadURL(ref)
        .then((url) => {
          setPreviewImageUrl(url)
        })
    }
  }

  const openImage = (fileName) => {
    fetchLink(fileName).then(() => setPreviewModalOpen(true))
  }

  const initiateDownload = (fileName) => {
    if (sessionId) {
      const ref = storageRef(storage, sessionId + '/' + fileName)
      getDownloadURL(ref)
        .then((url) => {
          const xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = (event) => {
            const blob = xhr.response;
            const blobUrl = window.URL.createObjectURL(
              new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = blobUrl;
            link.setAttribute(
              'download',
              fileName,
            );
            link.target = '_blank'
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          };

          xhr.open('GET', url);
          xhr.send();

        })
        .catch((error) => {
          // eslint-disable-next-line default-case
          switch (error.code) {
            case 'storage/object-not-found':
              // File doesn't exist
              break;
            case 'storage/unauthorized':
              // User doesn't have permission to access the object
              break;
            case 'storage/canceled':
              // User canceled the upload
              break;

            // ...

            case 'storage/unknown':
              // Unknown error occurred, inspect the server response
              break;
          }
        });
    }
  }

  const initiateDelete = (fileName) => {
    if (sessionId) {
      const fileRef = storageRef(storage, sessionId + '/' + fileName)
      deleteObject(fileRef).then(
        deleteFileFromList(fileName)
      )
    }
  }

  const uploadNewFiles = (files) => {
    if (sessionId) {
      files.forEach(f => {
        const ref = storageRef(storage, sessionId + '/' + f.name)
        uploadBytes(ref, f).then(result => {
          addFileToList(f.name)
        })
      })
    }
  }

  useEffect(() => {
    if (sessionId) {
      const dataRef = ref(database, sessionId);
      onValue(dataRef, snapshot => {
        const value = snapshot.val();
        if (value) {
          if (value.text) {
            dispatch(updateText(value.text))
          } else {
            dispatch(updateText(''))
          }
          if (value.files) {
            dispatch(updateFiles(value.files))
          } else {
            dispatch(updateFiles([]))
          }
        } else {
          dispatch(updateText(''))
          dispatch(updateFiles([]))
        }
      });
      return () => off(dataRef);
    }
  }, [sessionId, dispatch])

  const updateDbText = async (text) => {
    if (sessionId) {
      const currentValueRef = await ref(database, sessionId)
      const currentValue = (await get(currentValueRef)).val()
      const dataRef = ref(database);
      const updates = {}
      updates[sessionId] = {
        ...currentValue,
        text: text
      }
      await update(dataRef, updates);
    }
  }

  const handleUpdate = (e) => {
    updateDbText(e.currentTarget.value)
  }

  return (
    <MainLayout>

      <Modal
        open={previewModalOpen}
        onClose={() => setPreviewModalOpen(false)}
      >
        <Box sx={previewModalStyle}>
          <Paper elevation={0} style={{display: "flex", alignItems: 'center', justifyContent: 'center'}}>{previewImageUrl && <PreviewImage src={previewImageUrl}/>}</Paper>
        </Box>
      </Modal>

      <StyledHeading variant={"h4"}>
        <Edit/> Text
      </StyledHeading>
      <TextField
        placeholder="Type or paste text here..."
        multiline
        fullWidth={true}
        rows={25}
        value={text}
        onChange={handleUpdate}
      />
      <StyledHeading variant={"h4"}>
        <AttachFile/> Files
      </StyledHeading>
      <DropzoneArea showAlerts={false} filesLimit={0} showPreviews={false} showPreviewsInDropzone={false} onDrop={uploadNewFiles}/>
      <Box sx={{width: '100%', bgcolor: 'background.paper'}}>
        <List>
          {files.map(f => <ListItem key={f} secondaryAction={
            <>
              <IconButton onClick={() => initiateDownload(f)} edge="end" aria-label="delete">
                <FileDownload/>
              </IconButton>
              <IconButton onClick={() => initiateDelete(f)} edge="end" aria-label="delete">
                <Delete/>
              </IconButton>
              {isImage(f) && <IconButton onClick={() => openImage(f)} edge="end" aria-label="delete">
                <Visibility/>
              </IconButton>}
            </>
          }>
            <ListItemText style={{maxWidth: '100%', overflow: 'scroll', marginRight: '20px'}} primary={f}/>
          </ListItem>)}
        </List>
      </Box>
    </MainLayout>
  )
}

export default Session