import React from "react";
import {AppBar, Container, Toolbar, Typography} from "@mui/material";
import AppStoreIcon from "../assets/images/app-store.png"
import GooglePlayIcon from "../assets/images/google-play.png"
import {styled} from "@mui/material/styles";

const StyledAppstoreIconImage = styled('img')`
  height: 30px;
  margin: 5px;
`

const RightAlignedBox = styled('div')`
  justify-content: flex-end;
  display: flex;
  flex: 1;
`

const Footer = () => {
  return (
    <AppBar position="static" color="primary">
      <Container maxWidth="lg">
        <Toolbar>
          <Typography variant="body1" color="inherit">
            2021 SyncBin by <a style={{color: "#ffffff"}} href="https://thecaringdeveloper.com" rel="noreferrer"
                               target={"_blank"}>Roland Treiber</a>
          </Typography>
          <RightAlignedBox>
            <a target={"_blank"} href={"https://apps.apple.com/us/app/syncbin/id1629902171"}><StyledAppstoreIconImage src={AppStoreIcon}/></a>
            <a target={"_blank"} href={"https://play.google.com/store/apps/details?id=com.syncbin&hl=en&gl=US"}><StyledAppstoreIconImage src={GooglePlayIcon}/></a>
          </RightAlignedBox>
        </Toolbar>
      </Container>
    </AppBar>
  )
}

export default Footer
