export const UPDATE_TEXT = "UPDATE_TEXT"
export const ADD_FILE = "ADD_FILE"
export const REMOVE_FILE = "REMOVE_FILE"
export const UPDATE_FILES = "UPDATE_FILES"

export const updateText = (text) => {
  return {
    type: UPDATE_TEXT,
    payload: text
  }
}

export const updateFiles = (files) => {
  return {
    type: UPDATE_FILES,
    payload: files
  }
}

export const addFile = (file) => {
  return {
    type: ADD_FILE,
    payload: file
  }
}

export const removeFile = (id) => {
  return {
    type: REMOVE_FILE,
    payload: id
  }
}
