export const SET_SESSION_ID = "SET_SESSION_ID";
export const ADD_TO_QUICK_ACCESS = "ADD_TO_QUICK_ACCESS";
export const SET_QUICK_ACCESS = "SET_QUICK_ACCESS";
export const DELETE_FROM_QUICK_ACCESS = "DELETE_FROM_QUICK_ACCESS";

export const setSessionId = (sessionId) => {
  return {
    type: SET_SESSION_ID,
    payload: sessionId
  }
}

export const addToQuickAccess = (sessionId) => {
  return {
    type: ADD_TO_QUICK_ACCESS,
    payload: sessionId
  }
}

export const deleteFromQuickAccess = (sessionId) => {
  return {
    type: DELETE_FROM_QUICK_ACCESS,
    payload: sessionId
  }
}

export const setQuickAccess = (quickAccess) => {
  return {
    type: SET_QUICK_ACCESS,
    payload: quickAccess
  }
}