import {
  SET_SESSION_ID,
  ADD_TO_QUICK_ACCESS,
  DELETE_FROM_QUICK_ACCESS,
  SET_QUICK_ACCESS
} from "../actions/sessionActions";

const initState = {
  sessionId: "",
  quickAccess: []
}

const sessionReducer = (state = initState, action) => {
  const currentQuickAccessValue = state.quickAccess ? state.quickAccess : [];
  switch (action.type) {
    case SET_SESSION_ID:
      return {
        ...state,
        sessionId: action.payload
      }
    case SET_QUICK_ACCESS:
      return {
        ...state,
        quickAccess: action.payload
      }
    case ADD_TO_QUICK_ACCESS:
      const newQuickAccess = currentQuickAccessValue.indexOf(action.payload) === -1 ? [...currentQuickAccessValue, action.payload] : [...currentQuickAccessValue]
      return {
        ...state,
        quickAccess: [...newQuickAccess]
      }
    case DELETE_FROM_QUICK_ACCESS:
      const quickAccess = [...currentQuickAccessValue]
      const index = quickAccess.indexOf(action.payload)
      index !== -1 && quickAccess.splice(index, 1)
      return {
        ...state,
        quickAccess: [...quickAccess]
      }
    default:
      return state
  }
}

export default sessionReducer