import React, {useEffect, useState} from "react";
import {
  Container,
  Paper,
  AppBar,
  Typography,
  Toolbar,
  InputBase,
  Divider,
  IconButton,
  Modal,
  Box,
  Snackbar, Tabs, Tab
} from "@mui/material";
import {styled, alpha} from '@mui/material/styles';
import {Link, QrCode2, ContentCopy, Info, AddCircle, Delete} from '@mui/icons-material';
import {useDispatch, useSelector} from "react-redux";
import {setSessionId, addToQuickAccess, deleteFromQuickAccess} from "../redux/actions/sessionActions";
import {makeId} from "../utils/generators";
import Footer from "../components/footer";
import {useParams} from "react-router-dom";
import QRCode from "react-qr-code";
import {useNavigate} from "react-router-dom";
import LogoImg from "../assets/images/logo.svg"

const StyledDivider = styled(Divider)(({theme}) => ({
  marginTop: '15px',
  marginBottom: '15px'
}));

const StyledPaper = styled(Paper)(({theme}) => ({
  padding: '15px'
}));

const SessionIdInput = styled('div')(({theme}) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(3),
    width: 'auto',
  },
}));

const SessionIdInputWrapper = styled('div')(({theme}) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

const qrCodeModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const infoModalStyle = {
  ...modalStyle,
  width: '80%',
  minWidth: '300px',
  maxHeight: '60vh',
  overflow: 'scroll'
}

const Logo = styled('img')(({theme}) => ({
  maxHeight: '30px',
  alignSelf: 'center',
  display: 'flex',
  flex: 1
}));

const MainLayout = ({children}) => {
  const dispatch = useDispatch()
  const sessionId = useSelector((state) => state.session.sessionId)
  const quickAccess = useSelector((state) => state.session.quickAccess)
  const {id} = useParams()
  const [qrModalOpen, setQrModalOpen] = useState(false)
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [showCopySnackbar, setShowCopySnackbar] = useState(false)
  const navigate = useNavigate();
  const [showSession, setShowSession] = useState(false)

  useEffect(() => {
    if (id !== undefined) {
      dispatch(setSessionId(id))
      navigate("/");
    } else {
      sessionId === "" && dispatch(setSessionId(makeId(12)))
    }
  }, [dispatch, id, navigate, sessionId])

  useEffect(() => {
    if (sessionId.length === 0 && id === undefined) {
      dispatch(setSessionId(makeId(12)))
    }

    setShowSession(sessionId.length > 10)
  }, [sessionId, dispatch, id])

  const getQrCodeValue = () => {
    return process.env.REACT_APP_URL + sessionId;
  }

  const copyToClipboard = (text) => {
    const ta = document.createElement("textarea");
    ta.innerText = text;
    document.body.appendChild(ta);
    ta.select();
    document.execCommand("copy");
    ta.remove();
    setShowCopySnackbar(true)
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <Container maxWidth="lg">

      <Snackbar
        open={showCopySnackbar}
        autoHideDuration={3000}
        onClose={() => setShowCopySnackbar(false)}
        message="Copied to clipboard"
      />

      <Modal
        open={qrModalOpen}
        onClose={() => setQrModalOpen(false)}
      >
        <Box sx={qrCodeModalStyle}>
          <Paper><QRCode value={getQrCodeValue()}/></Paper>
        </Box>
      </Modal>

      <Modal
        open={infoModalOpen}
        onClose={() => setInfoModalOpen(false)}
      >
        <Box sx={infoModalStyle}>
          <Paper elevation={0}>
            <Typography variant={"h3"}>Hello</Typography>
            <Typography variant={"p"}>Thank you for using SyncBin!</Typography>
            <Typography variant={"p"}> It is a real time sharing board that allows sharing plain text or files between devices.</Typography>
            <StyledDivider/>
            <Typography variant={"h5"}>Please bear in mind the following:</Typography>
            <ul>
              <li>The data is not encrypted</li>
              <li>...nor the files</li>
              <li>Once you delete something, it is gone forever</li>
            </ul>
            <StyledDivider/>
            <Typography variant={"h5"}>Typical use case:</Typography>
            <Typography variant={"p"}>Have you ever wanted to quickly drop something from a Mac to a Linux or Windows Pc, that may physically be right next to it on your desk?<br/>If yes, have you wandered why isn't there a simple channel where you can drop text and files and they appear on the other machine right away? <br/></Typography>
            <Typography variant={"p"}>How about doing the same on mobile? You have 2 phones and want to drop a file and some text to the the other. <br/>One is iPhone while the other is Android.</Typography>
            <Typography variant={"p"}>What do you do? Have you ever sent an email to yourself just to copy the content and use it on another device - like most people? <br/></Typography>
            <Typography variant={"p"}>Well, if yes, then those days are over :)</Typography>
            <StyledDivider/>
            <Typography variant={"p"}>The solution: You simply open a SyncBin session on each machine and you're all set. All sessions are deleted every Sunday at midnight GMT+1</Typography>
            <StyledDivider/>
            <Typography variant={"h5"}>Security:</Typography>
            <Typography variant={"p"}>There is no tracking built in whatsoever. This app is designed to be transparent and easy to use. Once you discard a piece of information - be it text or files -, they are deleted irreversibly.<br/><br/></Typography>
            <Typography variant={"p"}>It is your responsibility not to leave anything sensitive in your session. It may be ok to paste a key without context for a few seconds, but once you're done, you should delete it.</Typography>
            <StyledDivider/>
            <Typography variant={"h4"}>That's it!</Typography>
            <Typography variant={"p"}>I hope you'll enjoy it and if you have any suggestions, please feel free to get in touch!</Typography>
            <StyledDivider/>
            <Typography variant={"h4"}>Donation</Typography>
            <Typography variant={"p"}>If you enjoy using SyncBin and want it to remain free and ad-free, please consider a donation.<br/><br/></Typography>
            <Typography variant={"p"}>The money goes towards servers and third party services as well as expanding the feature set.<br/><br/></Typography>
            <form action="https://www.paypal.com/donate" method="post" target="_blank">
              <input type="hidden" name="hosted_button_id" value="MS77D3JB5R33C" />
              <input type="image" src="https://www.paypalobjects.com/en_GB/i/btn/btn_donate_SM.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
              <img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="1" height="1" />
            </form>
          </Paper>
        </Box>
      </Modal>

      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{flexGrow: 1}}>
            <Logo src={LogoImg}/>
          </Typography>
          <SessionIdInput>
            <SessionIdInputWrapper>
              <Link/>
            </SessionIdInputWrapper>
            <StyledInputBase
              placeholder="Session Id…"
              inputProps={{'aria-label': 'search'}}
              value={sessionId}
              onChange={(e) => {
                dispatch(setSessionId(e.currentTarget.value))
              }}
            />
          </SessionIdInput>
          <IconButton color={"secondary"} onClick={() => {
            dispatch(addToQuickAccess(sessionId))
          }}>
            <AddCircle/>
          </IconButton>
          <IconButton color={"secondary"} onClick={() => {
            copyToClipboard(getQrCodeValue())
          }}>
            <ContentCopy/>
          </IconButton>
          <IconButton color={"secondary"} onClick={() => {
            setQrModalOpen(true)
          }}>
            <QrCode2/>
          </IconButton>
          <IconButton color={"secondary"} onClick={() => {
            setInfoModalOpen(true)
          }}>
            <Info/>
          </IconButton>
        </Toolbar>
      </AppBar>
      {quickAccess?.length > 0 && <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant="scrollable"
              scrollButtons="auto"
              style={{overflow: 'auto'}}
              value={quickAccess.indexOf(sessionId) !== -1 ? quickAccess.indexOf(sessionId) : false} aria-label="quick access tabs"
        >
          {quickAccess.map(session => (<Tab key={session}
                                            label={<span style={{display: 'flex', alignItems: 'center'}}>
                                              <span onClick={() => dispatch(setSessionId(session))}>{session}</span>
                                                <Delete style={{marginLeft: '3px'}} onClick={() => dispatch(deleteFromQuickAccess(session))} color={"warning"}/>
                                            </span>} {...a11yProps(session)} />))}
        </Tabs>
      </Box>}
      <StyledPaper elevation={3}>
        {showSession ? children : (
          <>
            <Typography variant={'h2'}>Oh no!</Typography>
            <Typography variant={'h4'}>Not a valid session id :(</Typography>
            <p>The session id needs to be at least 10 characters.</p>
            <p>Please remember not to leave sensitive information here.</p>
            <p></p>
          </>
        )}

      </StyledPaper>
      <StyledDivider/>

      <Footer/>
    </Container>
  )
}

export default MainLayout