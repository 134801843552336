import {UPDATE_TEXT} from "../actions/pasteboardActions";
import {ADD_FILE} from "../actions/pasteboardActions";
import {REMOVE_FILE} from "../actions/pasteboardActions";
import {UPDATE_FILES} from "../actions/pasteboardActions";
import {makeId} from "../../utils/generators";

const initState = {
  text: '',
  files: []
}

const sessionReducer = (state = initState, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case UPDATE_TEXT:
      return {
        ...state,
        text: action.payload
      }
    case UPDATE_FILES:
      return {
        ...state,
        files: action.payload
      }
    case ADD_FILE:
      return {
        ...state,
        files: [...state.files, {
          id: makeId(12),
          file: action.payload
        }]
      }
    case REMOVE_FILE:
      const fileId = action.payload
      const index = state.files.findIndex(f => {
        return f.id === fileId
      })
      const newFilesList = [...state.files].slice(index, 1)
      return {
        ...state,
        files: newFilesList
      }
    default:
      return state
  }
}

export default sessionReducer