import './App.css';
import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from "react-redux";
import configureStore from "./configureStore";
import MainContent from "./views/Session";
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
import {unstable_createMuiStrictModeTheme as createTheme} from '@mui/material/styles';
import {ThemeProvider} from "@mui/material";

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#333333',
    },
    secondary: {
      main: '#a5f500',
    },
  },
})

const persistentStoreConfig = configureStore();

function App() {

  return (
    <Provider store={persistentStoreConfig.store}>
      <ThemeProvider theme={theme}>
        <PersistGate loading={null} persistor={persistentStoreConfig.persistor}>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<MainContent/>}/>
              <Route path=":id" element={<MainContent/>}/>
            </Routes>
          </BrowserRouter>
        </PersistGate>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
