export const firebaseConfig = {
  apiKey: "AIzaSyAHyxw1CisG_zsgnjxcbO_CXAhvuVYRq7g",
  authDomain: "realtime-pasteboard.firebaseapp.com",
  projectId: "realtime-pasteboard",
  storageBucket: "realtime-pasteboard.appspot.com",
  messagingSenderId: "1028635777449",
  appId: "1:1028635777449:web:f0628ccddb488c3c8e0cb8",
  measurementId: "G-FCK8BDPQSW",
  databaseURL:
  'https://realtime-pasteboard-default-rtdb.europe-west1.firebasedatabase.app/'
};